<template>
  <section style="padding-top: 20px;">
    <div class="row" style="background-color: #f8f9fa;border: 1px solid #dee2e6;align-items: center;">
      <div class="col-12 md:col-1 lg:col-1" >
         <h4 style="font-size: 15px; margin-bottom: 0;">Colunas Exibidas :</h4>
      </div>
      <div v-for="(n, index) in new_filtro" :key="n"  class="col-12 md:col-1 lg:col-1" >
        <label style="margin-right:15px;">
          <input :checked="n.check" type="checkbox" @change="filtroAtualizar(n,index),newfiltro()"> {{ n.nome }}
        </label>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent ({
  props:{
    filtro:[]
  },
  data(){
    return{
      new_filtro:[]
    }
  },
  async beforeMount() {
    this.new_filtro = this.filtro;
  },
  methods: {
    filtroAtualizar(n,index){
      const newl = {
        nome:n.nome,
        check: !n.check,
        _id:n._id,
      }
      this.new_filtro[index] = newl;

      console.log(this.new_filtro)
    },
    newfiltro(){
      this.$emit('newfiltro',this.new_filtro)
    }
  }
});
</script>


