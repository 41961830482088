<template>
  <section>
    <div
      class="row"
      style="background-color: #f8f9fa; border: 1px solid #dee2e6"
    >
      <div class="field col-12 md:col-1 lg:col-1" v-if="modelo == 1">
        <label>Selecione o modelo</label>
        <select
          class="p-inputtext p-component"
          style="appearance: revert !important; width: 100% !important"
          v-model="selecao.modelo"
        >
          <option value="modelo01">Modelo 01</option>
        </select>
      </div>

      <div class="field col-12 md:col-6 lg:col-1" v-if="anoALL">
        <label class="ft">Ano:</label>
        <select
          disabled
          class="p-inputtext p-component"
          style="appearance: revert !important; width: 100% !important"
          v-model="info.ano"
        >
          <option v-for="a in anoALL" :key="a.ano" :value="a.ano">
            {{ a.ano }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-6 lg:col-1"
        v-if="anoALL && individual == 1"
      >
        <label> Buscar por:</label>
        <select
          class="p-inputtext p-component"
          style="appearance: revert !important; width: 100% !important"
          v-model="info.individual"
        >
          <option value="1">Individual</option>
          <option value="0">Turma</option>
        </select>
      </div>

      <BuscarAluno @buscarAluno="buscarAluno" v-if="info.individual === '1'" />

      <div
        class="field col-12 md:col-2 lg:col-2"
        v-if="info.individual === '0'"
      >
        <label class="ft">Selecione a Escola</label>
        <select
          class="p-inputtext p-component"
          v-model="info.escola_id"
          style="appearance: revert !important; width: 100% !important"
          @change="buscarSegmentoALL()"
        >
          <option value="0" disabled selected>-- Escolha uma Escola --</option>
          <option v-for="n in escolaALL" :key="n" :value="n.id">
            {{ n.nome }}
          </option>
        </select>
      </div>
      <div
        class="field col-12 md:col-2 lg:col-2"
        v-if="info.individual === '0' && objeto === false"
      >
        <label class="ft"> Selecione o Segmento</label>

        <select
          class="p-inputtext p-component"
          v-model="info.segmento_id"
          @change="listaSerie(info.segmento_id)"
          style="appearance: revert !important; width: 100% !important"
        >
          <option value="0" disabled selected>-- Escolha um Segmento --</option>
          <option v-for="n in segmentoALL2" :key="n" :value="n.id">
            {{ n.nome }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-2 lg:col-2"
        v-if="info.individual === '0' && objeto === true"
      >
        <label class="ft">Selecione a Segmento</label>
        <select
          class="p-inputtext p-component"
          v-model="info.segmento_id"
          @change="listaSerie(info.segmento_id.id)"
          style="appearance: revert !important; width: 100% !important"
        >
          <option value="0" disabled selected>-- Escolha um Segmento --</option>
          <option v-for="n in segmentoALL2" :key="n" :value="n">
            {{ n.nome }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-6 lg:col-2"
        v-if="info.segmento_id.tipo === 3"
      >
        <label class="ft">Selecione o Semestre:</label>
        <select
          class="p-inputtext p-component"
          style="appearance: revert !important; width: 100% !important"
          v-model="info.semestre"
        >
          <option
            v-for="semestre in semestres"
            :key="semestre"
            :value="semestre"
          >
            {{ semestre }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-1 lg:col-2"
        v-if="info.individual === '0' && objeto === false"
      >
        <label class="ft">Selecione a Série</label>
        <select
          class="p-inputtext p-component"
          v-model="info.serie_id"
          @change="listaTurmas()"
          style="appearance: revert !important; width: 100% !important"
        >
          <option value="0" disabled selected>-- Escolha uma serie --</option>
          <option v-for="n in serieALL" :key="n" :value="n.id">
            {{ n.nome }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-1 lg:col-2"
        v-if="info.individual === '0' && objeto === true"
      >
        <label class="ft">Selecione a Série</label>
        <select
          class="p-inputtext p-component"
          v-model="info.serie_id"
          @change="listaTurmas()"
          style="appearance: revert !important; width: 100% !important"
        >
          <option value="0" disabled selected>-- Escolha uma serie --</option>
          <option v-for="n in serieALL" :key="n" :value="n">
            {{ n.nome }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-1 lg:col-2"
        v-if="info.individual === '0'"
      >
        <label class="ft">Selecione a Turma</label>
        <select
          class="p-inputtext p-component"
          v-model="info.turma_id"
          style="appearance: revert !important; width: 100% !important"
        >
          <option value="0" disabled selected>-- Escolha uma Turma--</option>
          <option v-for="n in turmaALL" :key="n" :value="n.id">
            {{ n.nome }}
          </option>
        </select>
      </div>

      <div
        class="field col-12 md:col-3 lg:col-3"
        v-if="info.turma_id != undefined && info.individual === '0'"
        style="margin-top: 31px"
      >
        <button
          v-if="aguardeLoading == false"
          class="btn btn-primary"
          @click="buscarRelatorio()"
        >
          Pesquisar
        </button>

        <button disabled v-if="aguardeLoading == true" class="btn btn-primary">
          Aguarde...
        </button>
      </div>
    </div>

    <filtrosCheckbox @checkout="checkout" :filtro="filtro" v-if="filtro" />
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { LoginInfo } from "@/class/login";
import filtrosCheckbox from "./filtrosCheckbox.vue";

import BuscarAluno from "./buscarAluno.vue";

export default defineComponent({
  components: {
    BuscarAluno,
    filtrosCheckbox,
  },
  props: {
    modelo: 0,
    individual: 0,
    filtro: [],
    objeto: false,
  },
  data() {
    return {
      semestres: [1, 2],
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/ficha-individual" },
      items_bread: [{ label: "Transportes Alunos" }],

      selecao: {
        modelo: "modelo01",
      },
      pesquisar_modelo: false,
      display_pdf: false,
      visualizarorde: false,

      aluno: [],

      segmento_item: [],
      serie_item: [],
      itemS: [],
      escolaALL: [],
      relatorioALL: [],
      segmentoALL: [],
      segmentoALL2: [],
      serieALL: [],
      turmaALL: [],
      anoAll: [],
      info: {
        ano: 2023,
        escola_id: 0,
        segmento_id: 0,
        aluno: null,
        filtro: null,
        serie_id: null,
        turma_id: null,
        individual: "0",
        semestre: 1,
      },
      segmentos: [],
      serie: {},
      qdt_seg: 0,
      todos_segmentos: 1,
      colunas: {
        numero: true,
      },
      imprimir: 0,
      visivel: false,
      aguardeLoading: false,
      opentable: false,
      funcao: 0,
      who: [],
      diretor: [],
      coordenador: [],
    };
  },
  async beforeMount() {
    this.info.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    await this.logado();
    this.anosCalendario();
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
    this.info.filtro = this.filtro;
  },
  methods: {
    async logado() {
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      this.funcao = whoiam.data.funcao;
      this.who = whoiam.data;
    },

    async anosCalendario() {
      const dt = await Calendario.ano();
      this.anoALL = dt.data;
      this.anoALL.sort((a, b) => (a.ano > b.ano ? 1 : b.ano > a.ano ? -1 : 0));
    },

    async buscarEscolaALL() {
      this.escolaALL = [];
      //gestores
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        const data = await Escola.obtemTodos();
        this.escolaALL = data.data;
      }
      //diretores
      if (this.funcao === 3 || this.who.diretor === 1) {
        await axios
          .get("/diretor/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
          .then((resp) => {
            this.diretor = resp.data.id;
          })
          .catch((err) => {});

        const data = await Escola.obtemUm(this.diretor.escola);
        this.escolaALL.push(data.data);
      }
      //coordenadores
      if (this.funcao === 4) {
        const storageEscolas = sessionStorage.getItem("escolas");
        if (!storageEscolas) {
          await axios
            .get("/coordenador/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
            .then((resp) => {
              this.coordenador = resp.data;
              sessionStorage.setItem(
                "coordenador_r",
                JSON.stringify(this.coordenador)
              );
            })
            .catch((err) => {});

          const escolas = this.coordenador.esc;
          for (let i = 0; i < escolas.length; i++) {
            const data = await Escola.obtemUm(escolas[i]);
            this.escolaALL.push(data.data);
          }
          sessionStorage.setItem("escolas", JSON.stringify(this.escolaALL));
        } else {
          this.escolaALL = JSON.parse(storageEscolas);

          const storageCoordenador = sessionStorage.getItem("coordenador_r");
          this.coordenador = JSON.parse(storageCoordenador);
        }
      }
    },

    async buscarSegmentoALL() {
      if(this.info.escola_id){
        this.segmentoALL2 = []
        const data = await SegmentoEscolar.obtemSegmentosEscola(this.info.escola_id);
        this.segmentoALL2 = data.data;
      }else{
        this.segmentoALL2 = []
      }
      // if (this.funcao === 4) {
      //   const data = await SegmentoEscolar.obtemSegmentosEsp();
      //   this.segmentoALL2 = data.data;
      // } else {
      //   const data = await SegmentoEscolar.obtemTodos();
      //   this.segmentoALL2 = data.data;
      // }
    },

    async listaSerie(id) {
      this.turma_id = null;
      this.serie_id = null;
      //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.info.escola_id,id);
      this.serieALL = data.data;
      return this.seriesALL;
    },

    async listaTurmas() {
      let play;
      if (this.objeto === true) {
        play = {
          escola: this.info.escola_id,
          serie: this.info.serie_id.id,
          segmento: this.info.segmento_id.id,
        };
      } else {
        play = {
          escola: this.info.escola_id,
          serie: this.info.serie_id,
          segmento: this.info.segmento_id,
        };
      }

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    buscarRelatorio() {
      this.aguardeLoading = true;
      this.$emit("buscarRelatorio", this.info);
      setInterval(() => {
        this.aguardeLoading = false;
      }, 2000);
    },

    async buscarAluno(aluno) {
      const data = await axios.get(
        "/alunos/v2/" + aluno.nome + "/sobrenome/" + aluno.sobrenome + "/0"
      );
      const segmento = this.segmentoALL2.find(
        (el) => el.id === data.data[0].turma.segmento_id
      );

      await this.listaSerie(segmento.id);

      const serie = this.serieALL.find(
        (el) => el.id === data.data[0].turma.serie_id
      );

      this.info.aluno = data.data[0].id;
      this.info.segmento_id = segmento;
      this.info.serie_id = serie;
      console.log("alo", this.info);
      this.buscarRelatorio();
    },
    newfiltro(filtro) {
      this.info.filtro = filtro;
    },
  },
});
</script>

<style scoped>
.ft {
  font-size: 15px!important;
}
</style>


